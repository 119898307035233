// redoc theme
$redoc-main: #334066;
$redoc-orange: #334066; //#ff9900;
$redoc-white: #ffffff;
$redoc-link: #9da3b6;
$redoc-red: #cc3333;
$redoc-sidebar: #336699;

$navbar-bg: $redoc-main;
$navbar-color: $redoc-white;
$navbar-hover-color: $redoc-link;
$navbar-active-color: $redoc-white;
$navbar-brand-bg: $redoc-orange;
$navbar-border: 1px $redoc-main;

$card-cap-bg: $redoc-main;
$card-border-color: $redoc-main;

.card-header {
	color: #ffffff;
}

$sidebar-nav-dropdown-bg: #5b6685;

$sidebar-nav-link-hover-bg: $redoc-link;
$sidebar-nav-link-active-bg: $redoc-link;
$sidebar-nav-link-active-icon-color: $redoc-white;
$sidebar-nav-link-icon-color: $redoc-white;

$footer-bg: #f6f9fa;

$primary: $redoc-orange;
$danger: $redoc-red;

$sidebar-bg: $redoc-sidebar;
$body-bg: #f6f9fa;

body {
	background: rgb(244,247,249);
	background: linear-gradient(90deg, rgba(244,247,249,1) 10%, rgba(226,234,238,1) 100%);
}

//menu theme redoc
$nested-items-light: #3f7ebe;
$selected-item-dark: $redoc-orange;
$main-menu-color: $redoc-sidebar;
$indent-step-menu: 20px;
