@import "variables";
@import "../common/style.scss";

.rdev__logo {
	background: url(scss/redoc/img/logoredoc.png) 50% 50% no-repeat;
	background-size: 90px;
	position: relative;
	height: 55px;
	width: 122px;
}

@media (max-width: 390px) {
	.rdev__logo {
		background: url(scss/redoc/img/logoredoc_mini.png) 50% 50% no-repeat;
		background-size: contain;
		height: 30px;
		width: 30px;
		margin-left: 10px;
		margin-right: 10px;
		display: block;
	}
}

// https://rm.mfc.ru/issues/44066
.crypt_list {
	background: none;
	&_item {
		margin: 2px;
		
		color: #CAF5FF;
		background-color: #336699;
	}

	&_item:hover {
		background-color: #5A83AC;
	}
}

.crypt_item_part:last-child {
	color: #A7BDD3;
}

.crypt_email {
	color: #A7BDD3;
}
